@import url('https://fonts.cdnfonts.com/css/futura-pt');
@import url('https://fonts.cdnfonts.com/css/gotham-6');

.navbar-link-style {
    color: black;
    font-family: 'Futura PT', sans-serif;                                            
    font-weight: 200;
    text-decoration: none;
    text-transform: uppercase;
}

.navbar {
    background-color: white;
    margin-left: 2%;
    margin-right: 2%;
    padding-top: 15%;
    padding-bottom: 15%;
    height: fit-content;
    justify-content: center;
}

.navbar a {
    color: black;
    font-family: 'Futura PT', sans-serif;                                              
    font-weight: 400;
    letter-spacing: 4px;
    font-size: 1rem;
    text-decoration: none;
    
}

.navbar .nav-item {
    margin-left: 2.0rem;
    padding-top: 2%;
    padding-bottom: 2%;
}

.navbar button {
    float: right;
    display: block;
    width: fit-content;
    height: 100%;
    left: 0;
    right: 0;
}

.brand-icon {
    width: 320px;
    height: 100%;
    display: block;
}

.brand-text {
    text-transform: uppercase;
    font-family: 'Gotham Black', sans-serif;                                            
    font-weight: 600;
    color: black;
    font-size: 2.5rem;
    display: inline;
    margin-top: 0;
    margin-bottom: 0;
}

#footer {
    position: relative;
    display: block;
    width: 100%;
    bottom: 0;
    background-color: white;
    font-family: 'Gotham', sans-serif;   
    font-size: 1rem;
    margin-top: auto;
 }

.icon-padding {
    padding-left: 1.5%;
    padding-right: 1.5%;
    font-size: 1.5rem;
}

.icon-padding:hover {
    color: gray;
}

.link-color {
    color: black;
}

.paragraph-style {
    font-family: 'Gotham', sans-serif;                                            
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;
    margin-top: 1.2rem;
    padding-bottom: 5%;
    text-align: justify;
}

#statement {
    padding-left: 1.1%;
    padding-right: 1.1%;
    text-align: center;
    justify-content: center;
    justify-items: center;
    align-self: center;                                          
    margin-top: 5rem;
    padding-bottom: 5%;
}

.statement-style {
    font-family: 'Gotham Light', sans-serif;
    font-weight: 400;                                          
    text-decoration: none;
    text-align: left;
    text-transform: uppercase;
}


#statement img {
    width: 100%;
    height: fit-content;
}

#statement svg:hover {
    color: black;
}

#statement p {
    margin-top: 5%;
    padding-bottom: 5%;
    text-align: justify;
}

.icon-size {
    font-size: 3rem;
    font-stretch:narrower;
    color:   gray;
    margin-bottom: 5%;
}

.info-spacing {
    padding-bottom: 5%;
}

.info-text {
    font-family: 'Gotham', sans-serif;   
    font-weight: 200;
    text-decoration: none;
}


.align-items-center {
    display: flex; 
    align-items: center;  /*Aligns vertically center */
    justify-content: center; /*Aligns horizontally center */
}


#brands {
    padding: 5% 15%;
    text-align: center;
    font-family: 'Gotham', sans-serif;   
    font-weight: 400;
    margin-top: 5rem;

}

#brands img {
    width: 20%;
    margin: 20px 20px 50px;
}

#works {
    padding: 5% 0.75%;
    text-align: center;
    justify-content: center;
    justify-items: center;
    align-self: center;
    font-family: 'Gotham', sans-serif;   
    font-weight: 400;
    margin-top: 2rem;
}

.work-text {
    font-family: 'Gotham', sans-serif;   
    font-weight: 800;
    text-decoration: none;
    color: #088395;
    text-transform: uppercase;
    padding-bottom: 10%;
    
}

.work-title {
    margin-top: 5%;
    font-family: 'Gotham', sans-serif;   
    font-weight: 800;
    text-decoration: none;
    color: black;
    text-transform: uppercase;
    padding-bottom: 5%;

}

#works .small-image {
    width: 100%;
    height: 300px;
}

#works .medium-image {
    width: 100%;
    height: 300px;
}

#works .large-image {
    width: 100%;
    height: 600px;
}

.image-box {
    background-size: cover;
    position: relative;
    width: fit-content;
    height: 100%;
    z-index: 10;
    padding: 0;
}



.content-overlay {
    position: absolute;
    width: 100%;
    height: fit-content;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    text-align: center;
    vertical-align: middle;
    z-index: 99;
    font-family: 'Gotham', sans-serif;
    overflow: hidden;
    color: white;
}

.content-title {
    font-size: calc(30px + 0.3vw);
    font-weight: 500;
    text-shadow: 1px 1px 2px black;
}

.overlay-location {
    text-transform: uppercase;
    font-family: 'Gotham', sans-serif;
    font-size: calc(25px + 0.3vw);
    font-weight: 400;
    text-shadow: 1px 1px 2px black;
}

.col-lg-12 .content-title {
    font-size: calc(65px + 0.3vw);
}

.col-lg-12 .overlay-location {
    font-size: calc(55px + 0.3vw);
}


#contact {
    background-color: white;
    font-family: 'Gotham', sans-serif;   
    font-weight: 200;
    text-align: center;
    padding: 10% 10%;
    text-transform: uppercase;

}

#contact h5 {
    font-weight: 400;
    font-family: 'Gotham', sans-serif;
}

#contact a {
    text-decoration: none;
}

#contact a:hover {
    text-decoration: underline;
    font-weight: 400;
}

#contact p {
    padding-top: 5%;
}

.contact-text {
    padding-bottom: 5%;
    color: black;
    font-family: 'Gotham', sans-serif;   
    font-weight: 200;
}

.contact-info {
    padding-bottom: 5%;
}


.google-maps {
    position: relative;
    padding-bottom: 75%; 
    height: 0;
    overflow: hidden;
}

.google-maps iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}

#posts-upper {
    background-color: white;
    font-family: 'Gotham',sans-serif;
    font-weight: 600;
    text-align: center;
    padding-top: 2.5%;
    padding-bottom: 2%;
    text-transform: uppercase;
}

#posts-middle {
    background-color: white;
    font-weight: 600;
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 2.5%;
    padding-bottom: 2%;
    text-transform: uppercase;
}

.posts-image {
    width: 100%;
    height: 100%;
}

.posts-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    text-align: center;
    vertical-align: middle;
    width: fit-content;
    height: fit-content;
    z-index: 99;
    font-family: 'Gotham', sans-serif;   
    color: white;
}

.posts-title {
    text-transform: uppercase;
    font-family: 'Gotham', sans-serif;
    font-size: 3.5rem;  
    font-weight: 400;
    background: rgba(0, 0, 0, 0.5);
}

.posts-text {
    font-family: 'Gotham', sans-serif;
    font-weight: 600;
    text-decoration: none;
    color: black;
    text-transform: uppercase;
    padding-top: 2.5%;
    padding-bottom: 2.5%;
}

#postsGallery {
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 15%;
    justify-content: center;
    justify-items: center;
    align-self: center;
    font-weight: 400;
}

#postsGallery img {
    width: 100%;
    height: 300px;
    margin-top: 5%;
    margin-bottom: 5%;
}


@media (max-width: 768.00px) {
    .content-title {
        font-size: calc(20px + 0.3vw);
    }
    .overlay-location {
        font-size: calc(20px + 0.3vw);
    }
    .col-lg-12 .content-title {
        font-size: calc(20px + 0.3vw);
    }
    
    .col-lg-12 .overlay-location {
        font-size: calc(20px + 0.3vw);
    }
    #statement {
        padding-left: 0;
        padding-right: 0;
    }
    .icon-padding {
        padding-left: 3%;
        padding-right: 3%;
    }
    .brand-icon {
        width: 200px;
        height: 100%;
        display: block;
    }
    #works .small-image {
        width: 100%;
        height: 268px;
    }
    
    #works .medium-image {
        width: 100%;
        height: 268px;
    }
    
    #works .large-image {
        width: 100%;
        height: 268px;
    }
  }

  @media (max-width: 290.00px) {
    .brand-icon {
        width: 150px;
        height: 100%;
        display: block;
    }
  }

  
  @media (max-width: 232.00px) {
    .brand-icon {
        width: 120px;
        height: 100%;
        display: block;
    }
  }

